class PWAInstall {
  constructor() {
    this.deferredPrompt = null;
    this.state = {
      isInstallable: false,
      isInstalling: false,
      isIOS: /iphone|ipad|ipod/.test(navigator.userAgent.toLowerCase()),
      isAndroid: /android/.test(navigator.userAgent.toLowerCase()),
      isSamsung: /samsungbrowser/.test(navigator.userAgent.toLowerCase()),
      isChrome: /chrome/.test(navigator.userAgent.toLowerCase()) && !/edge/.test(navigator.userAgent.toLowerCase()),
      installSource: null
    };
    this.init();
  }

  init() {
    this.createInstallButton();
    window.addEventListener('beforeinstallprompt', (e) => {
      e.preventDefault();
      this.deferredPrompt = e;
      this.state.isInstallable = true;
      this.state.installSource = 'native';
      setTimeout(() => this.showInstallPrompt(), 5000);
    });

    window.addEventListener('appinstalled', () => {
      this.state.isInstalling = false;
      this.hideInstallPrompt();
    });
  }

  createInstallButton() {
    const button = document.createElement('div');
    button.id = 'pwa-install';
    button.innerHTML = `
      <div class="install-dialog">
        <div class="install-header">
          <svg width="24" height="24" viewBox="0 0 24 24" fill="none">
            <path d="M12 2L2 7v6c0 5 3.8 9.3 9 9.9 5.2-.6 9-4.9 9-9.9V7L12 2z" stroke="currentColor" stroke-width="2" stroke-linecap="round"/>
            <path d="M12 6v8M8 10l4 4 4-4" stroke="currentColor" stroke-width="2" stroke-linecap="round"/>
          </svg>
          <h3>Install as an App</h3>
          <button id="close-install">
            <svg width="20" height="20" viewBox="0 0 20 20">
              <path d="M15 5L5 15M5 5l10 10" stroke="currentColor" stroke-width="2" stroke-linecap="round"/>
            </svg>
          </button>
        </div>
        <div class="install-content">
          <p>Add AI Chat to your home screen for quick access</p>
          <button id="install-button">
            <span class="button-content">Install now</span>
            <div class="loading-spinner"></div>
          </button>
        </div>
      </div>
      <style>
        #pwa-install {
          display: none;
          position: fixed;
          top: 0;
          left: 0;
          right: 0;
          bottom: 0;
          background: rgba(0,0,0,0.75);
          z-index: 9999;
          justify-content: center;
          align-items: center;
          backdrop-filter: blur(4px);
        }
        .install-dialog {
          background: white;
          width: 90%;
          max-width: 360px;
          border-radius: 16px;
          box-shadow: 0 20px 25px -5px rgba(0,0,0,0.1), 0 10px 10px -5px rgba(0,0,0,0.04);
          overflow: hidden;
        }
        .install-header {
          display: flex;
          align-items: center;
          padding: 16px;
          border-bottom: 1px solid #eee;
          color: #1a1a1a;
        }
        .install-header svg {
          margin-right: 12px;
          color: #f14472;
        }
        .install-header h3 {
          margin: 0;
          font-size: 18px;
          flex: 1;
        }
        #close-install {
          padding: 4px;
          background: none;
          border: none;
          color: #666;
          cursor: pointer;
          border-radius: 50%;
        }
        #close-install:hover {
          background: #f5f5f5;
        }
        .install-content {
          padding: 20px;
          text-align: center;
        }
        .install-content p {
          margin: 0 0 20px;
          color: #666;
        }
        #install-button {
          position: relative;
          width: 100%;
          padding: 12px;
          background: linear-gradient(-45deg, #ff5858, #f857a6);
          border: none;
          border-radius: 8px;
          color: white;
          font-size: 16px;
          font-weight: 500;
          cursor: pointer;
          overflow: hidden;
        }
        #install-button:hover {
          background: linear-gradient(-45deg, #ff4747, #f74695);
        }
        .button-content {
          opacity: 1;
          transition: opacity 0.2s;
        }
        .loading-spinner {
          position: absolute;
          top: 50%;
          left: 50%;
          transform: translate(-50%, -50%);
          width: 20px;
          height: 20px;
          border: 2px solid rgba(255,255,255,0.3);
          border-radius: 50%;
          border-top-color: white;
          opacity: 0;
          visibility: hidden;
        }
        .installing .button-content {
          opacity: 0;
        }
        .installing .loading-spinner {
          opacity: 1;
          visibility: visible;
          animation: spin 0.8s linear infinite;
        }
        @keyframes spin {
          to { transform: translate(-50%, -50%) rotate(360deg); }
        }
      </style>
    `;
    document.body.appendChild(button);

    document.getElementById('install-button').addEventListener('click', () => this.handleInstall());
    document.getElementById('close-install').addEventListener('click', () => this.hideInstallPrompt());
  }

  async handleInstall() {
    const button = document.getElementById('install-button');
    button.classList.add('installing');
    this.state.isInstalling = true;

    if (this.state.isIOS) {
      this.showIOSInstructions();
    } else if (this.state.isSamsung) {
      await this.handleSamsungInstall();
    } else if (this.deferredPrompt) {
      try {
        await this.deferredPrompt.prompt();
        const result = await this.deferredPrompt.userChoice;
        if (result.outcome === 'accepted') {
          this.deferredPrompt = null;
        }
      } catch (error) {
        console.error(error);
      }
    }

    setTimeout(() => {
      button.classList.remove('installing');
      this.state.isInstalling = false;
    }, 2000);
  }

  showIOSInstructions() {
    const content = document.querySelector('.install-content');
    content.innerHTML = `
      <div class="ios-instructions">
        <p>To install this app:</p>
        <ol>
          <li>Tap the Share button <svg width="16" height="16" viewBox="0 0 16 16"><path d="M11 2.5v2h2.5l-7 7-1.4-1.4 7-7V5.5h2V2.5z" fill="currentColor"/></svg></li>
          <li>Select "Add to Home Screen" <svg width="16" height="16" viewBox="0 0 16 16"><path d="M8 2v8M4 6l4 4 4-4" stroke="currentColor" fill="none" stroke-width="2"/></svg></li>
          <li>Tap "Add" to install</li>
        </ol>
        <button onclick="this.parentElement.remove()" class="close-ios">Got it</button>
      </div>
      <style>
        .ios-instructions {
          text-align: left;
          color: #666;
        }
        .ios-instructions ol {
          margin: 20px 0;
          padding-left: 24px;
        }
        .ios-instructions li {
          margin: 12px 0;
          display: flex;
          align-items: center;
          gap: 8px;
        }
        .close-ios {
          width: 100%;
          padding: 12px;
          background: #f5f5f5;
          border: none;
          border-radius: 8px;
          color: #333;
          font-size: 16px;
          cursor: pointer;
          margin-top: 16px;
        }
      </style>
    `;
  }

  async handleSamsungInstall() {
    if (document.documentElement.requestFullscreen) {
      await document.documentElement.requestFullscreen();
    }
    if (screen.orientation?.lock) {
      await screen.orientation.lock('portrait');
    }
    const confirmed = window.confirm('Install this app on your device?\n\nThe app will be installed on your home screen for quick access.');
    if (!confirmed) {
      throw new Error('User rejected Samsung installation');
    }
  }

  showInstallPrompt() {
    if ((this.deferredPrompt || this.state.isIOS || this.state.isSamsung) && !this.state.isInstalling) {
      document.getElementById('pwa-install').style.display = 'flex';
    }
  }

  hideInstallPrompt() {
    document.getElementById('pwa-install').style.display = 'none';
  }
}
